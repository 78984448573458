.custom-navbar {
    background-color: white !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    margin-bottom: 550px;
    padding: 0;
    border-bottom: 1px solid #dab161;
    /* border-bottom: 1px solid black; */
}

.navbar-logo{
    width: 100px;
    height: auto;
}
.navbar-brand-text{
    color: #dab161;
    font-size: 28px;
}

