  .slideshow-container {
    position: relative;
    margin: auto;
    width:90%
  }
  
  /* Hide the images by default */
  .mySlides {
    display: none;
  }
  
  .slide-images{
    margin-left: 10%;
    border-radius: 50px;
    width: 80%;
    height: auto;
  }
  .prev-next{
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 80%;
    top: 40%;
    margin-left: 10%;
  }
  
  .modal-content-wrapper {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .modal-content-wrapper .prev,
  .modal-content-wrapper .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  .modal-content-wrapper .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .modal-content-wrapper .prev:hover,
  .modal-content-wrapper .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  .modal-dialog{
    max-width: 50%;
  }
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  .next-thumbnails, .prev-thumbnails{
    background: none;
    border: none;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  .thumbnail-slider {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  
  .thumbnail {
    width: 60px;
    height: 40px;
    margin: 20px 5px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
  }
  
  .thumbnail-active {
    width: 100px;
    height: 80px;
    border: 2px solid #ddd;
    opacity: 1;
    transform: scale(1.1);
    border: #fcc201 2px solid; /* Blue border for active thumbnail */
    margin: 0px 5px;
    padding: 6px 4px;
    /* transition: transform 0.5s ease-in-out; */
  }
  .thumbnail-slider-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  .thumbnail:hover {
    opacity: 1;
  }
  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  .numbertext {
    color: black;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 100%;
    left: 47%;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
 .dot:hover, .dot-active {
    background-color: #717171;
  }
  
  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  @media (max-width: 768px){
    .slide-images{
        height: 400px;
    }
  }



  @media (max-width: 420px){
    .slideshow-container {
      position: relative;
      margin: auto;
      width:  100%
    }
    .slide-images{
      border-radius: 25px;
      width: 94%;
      height: auto;
      margin: 0;
      margin-left: 3%
    }
    .prev, .next{
      font-size: 10px;
    }
    .prev-next{
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 90%;
      top: 40%;
      margin-left: 5%;
    }
    .modal-dialog{
      max-width: 100%;
    }
  }

  @media (min-width:421px) and (max-width: 768px){
    .slideshow-container {
      position: relative;
      margin: auto;
      width:  100%
    }
    .slide-images{
      border-radius: 25px;
      width: 80%;
      height: auto;
      margin: 0;
      margin-left: 10%
    }
    .prev, .next{
      font-size: 14px;
    }
    .prev-next{
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 76%;
      top: 40%;
      margin-left: 12%;
    }
    .modal-dialog{
      max-width: 100%;
    }
  }

  @media (min-width:769px) and (max-width: 1025px){
    .slideshow-container {
      position: relative;
      margin: auto;
      width:  100%
    }
    .slide-images{
      border-radius: 25px;
      width: 64%;
      height: auto;
      margin: 0;
      margin-left: 18%
    }
    .prev, .next{
      font-size: 10px;
    }
    .prev-next{
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 60%;
      top: 40%;
      margin-left: 20%;
    }
    .modal-dialog{
      max-width: 100%;
    }
  }

