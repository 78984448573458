.services {
    padding-top: 50px;
    padding-bottom: 100px;
}

.divider {
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    /* background-color: #eed171; */
}

.services-div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.services-list{
    text-align: left;
}
.services-list ul{
    padding-left: 0px;
}
.services-list ul li {
    position: relative;
    left: 0;
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid #dab161;
    transition: 0.5s;
    cursor: pointer;
    padding: 4px;
    padding-left: 12px;
    display: inline-block;
}
.services-div i{
    color: black;
}

@media (max-width: 768px) {
    .services-div {
        width: 100%;
    }
}