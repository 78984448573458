.faq-section{
    margin-top: 100px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
}

.accordion-button{
    font-size: 16px;
    font-weight: 600;
    position: static;
}

.accordion-body{
    text-align: left;
    font-size: 16px;   
}
.accordion-button:focus{
    box-shadow: none;
}   
.accordion-button:not(.collapsed){
    background-color: #f4ead4;
}
.faq-top-container{
    border-top: 2px solid #dab161;
    border-bottom: 2px solid #dab161;
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
}
.right-faq-col{
    border-left: 2px solid #dab161;
    padding-top: 5%;
    padding-bottom: 5%;
    display:flex;
}
.left-faq-col{
    margin-top: auto;
    margin-bottom: auto;
}
.faq-btn{
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px){
    .faq-section{
        width: 90%;
    }  
    .faq-top-container{
        border-top: none;
        border-bottom: none;
        margin-top: 5%;
        /* padding-left: 5%; */
        /* padding-right: 5%; */
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .right-faq-col{
        border-left: none;
        padding-top: 5%;
        padding-bottom: 5%;
        display: block;
    }
}