#about-us{
    margin-top: 5%;
    margin-bottom: 5%;
    /* overflow-x: scroll  ; */
}
.profile-image{
    margin-bottom: 5%;
    max-width: 50%;
}
.card-footer{
    border: none;
}