.left-side{
    border-right: #fcc201 solid 2px;
    padding-top: 40px;
    padding-bottom: 40px;
}
.main-container{
    width: 100%;
    height: 100%;
    margin-top: 10%;
}
.container-contact-us{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-side {
    margin-top: auto;
    margin-bottom: auto;
    display: block;
}
.left-side p, .right-side p {
    font-size: 18px;
}
.contact-methods{
    font-size: 16px;
    font-style: italic;
    text-decoration: underline;
    color: black;
    font-weight: 400;
}

@media (max-width: 768px) {
    .main-container{
        margin-top: 30%;
        margin-bottom: 30%;
    }
    .container-contact-us{
        margin-left: 5%;
        width: 90%;
        padding-left: 0;
        padding-right: 0;
    }
    .left-side{
        border: none;
        padding-top: 0;
        padding-bottom: 0;
    }
    .right-side{
        width: 90%;
        border-top: #fcc201 solid 2px;
        margin-top: 50px;
    }
    .right-side p {
        margin-top: 40px;
    }
}