

.bg-gradient-primary-to-secondary {
  background: linear-gradient(to right, #b78628, #c69320, #dba514, #eeb609, #fcc201);
}

.text-gradient {
  background: linear-gradient(to right,
  #c96e15 ,   /* Lighter Gold extends to 30% */
  #ba661d ,   /* Dark Gray extends to 70% */
  #974617,
  #6f2f14,
  #371a12 ); /* Lighter Wine Red extends to 100% */
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-btn{
  border-color: #dab161;
  background-color: white;
  color: #dab161;
}
.custom-btn:hover{
  border-color: #dab161;
  background-color: #dab161;
  color: white;
}
.profile {
  position: relative;
  height: 75vw;
  width: 75vw;
  border-radius: 5vw;
  margin-top: 5vw;
  max-height: 40rem;
  max-width: 40rem;
}
.profile .profile-img {
  height: 75vw;
  max-height: 45rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.profile .dots-1 {
  position: absolute;
  width: 3vw;
  right: 1.5vw;
  top: 4vw;
}
.profile .dots-1 svg {
  fill: #fff;
}
.profile .dots-2 {
  position: absolute;
  width: 3vw;
  right: -4vw;
  top: 6.05vw;
}
.profile .dots-2 svg {
  fill: #dab161;
}
.profile .dots-3 {
  position: absolute;
  width: 3vw;
  left: 1.5vw;
  bottom: 6.05vw;
}
.profile .dots-3 svg {
  fill: #fff;
}
.profile .dots-4 {
  position: absolute;
  width: 3vw;
  left: -4vw;
  bottom: 4vw;
}
.profile .dots-4 svg {
  fill: #dab161;
}

@media (min-width: 576px) {
  .profile {
    height: 60vw;
    width: 60vw;
  }
  .profile .profile-img {
    height: 60vw;
  }
  .profile .dots-1 {
    width: 3vw;
    right: 1.5vw;
  }
  .profile .dots-2 {
    width: 3vw;
    right: -4vw;
  }
  .profile .dots-3 {
    width: 3vw;
    left: 1.5vw;
  }
  .profile .dots-4 {
    width: 3vw;
    left: -4vw;
  }
}
@media (min-width: 768px) {
  .profile {
    height: 50vw;
    width: 50vw;
  }
  .profile .profile-img {
    height: 50vw;
  }
  .profile .dots-1 {
    width: 3vw;
    right: 1.5vw;
  }
  .profile .dots-2 {
    width: 3vw;
    right: -4vw;
  }
  .profile .dots-3 {
    width: 3vw;
    left: 1.5vw;
  }
  .profile .dots-4 {
    width: 3vw;
    left: -4vw;
  }
}
@media (min-width: 992px) {
  .profile {
    height: 40vw;
    width: 40vw;
  }
  .profile .profile-img {
    height: 40vw;
  }
  .profile .dots-1 {
    width: 1.5vw;
    right: 0.75vw;
  }
  .profile .dots-2 {
    width: 1.5vw;
    right: -2vw;
  }
  .profile .dots-3 {
    width: 1.5vw;
    left: 0.75vw;
  }
  .profile .dots-4 {
    width: 1.5vw;
    left: -2vw;
  }
}
@media (min-width: 1200px) {
  .profile {
    height: 35vw;
    width: 35vw;
  }
  .profile .profile-img {
    height: 35vw;
  }
  .profile .dots-1 {
    width: 1.5vw;
    right: 0.75vw;
  }
  .profile .dots-2 {
    width: 1.5vw;
    right: -2vw;
  }
  .profile .dots-3 {
    width: 1.5vw;
    left: 0.75vw;
  }
  .profile .dots-4 {
    width: 1.5vw;
    left: -2vw;
  }
}

@media (max-width: 1400px){
  .header{
    margin-top: 10%;
  }
  .inner-container{
    margin-top: 10%;
  }
}