.main-projects-section{
  margin-top: 50px;
  margin-bottom: 200px;
  width: 100%;
}
.divider-projects{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fcc201;
  height: 1.5px;
  border-radius: 10px;
  border: none;
}
.heading-projects{
  justify-content: center;
}

.hr-work-title{
  width: 6%;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
}
.carousel {
  display: block;
  overflow: hidden;
  width: 50%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-item {
  width: 350px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: white; /* Ensure text is visible on background */
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}
.showcase-img{
  position: relative;
}
.prev-next-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.prev, .next {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 16px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.slide-dots{
  text-align: center;
  margin-top: auto;
  position: absolute;
  top: 75%;
  left: 45%;
  /* z-index: -1000  ; */
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.view-more-btn{
  border-color:#fcc201;
}

.dot:hover, .dot-active {
background-color: #717171;
}

.description-all-projects {
padding: 20px;
}

.single-project{
  margin-bottom: 150px; 
}

.description-all-projects .lead{
  font-size: 1.5rem;
  font-weight: 300;
}

@media (max-width: 768px){
.description-all-projects{
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.hr-work-title{
  width: 15%;
}
}
@media (max-width: 400px) {
.showcase-img{
  min-height: 300px;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 300px;
  display: block;
}
}
@media (min-width:401px) and (max-width:700px){
.showcase-img{
  min-height: 350px;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 350px;
  display: block;
}
.showcase .showcase-text {
  padding: 2rem;
  padding-top: 2%;
  padding-bottom: 2%;
}

}
@media (min-width:701px) and (max-width:875px){
.showcase-img{
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 500px;
  display: block;
}
.showcase .showcase-text {
  padding: 2rem;
  padding-top: 2%;
  padding-bottom: 2%;
}
}
@media (min-width:875px) and (max-width:991px){
.showcase-img{
  min-height: 700px;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 700px;
  display: block;
}
}
@media (min-width:992px) and (max-width:1300px){
.right-side-image{
  min-height: 350px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 500px 350px;
}
.left-side-image{
  min-height: 350px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 500px 350px;
}
.showcase .showcase-text {
  padding: 2rem;
  padding-top: 0%;
}

.row{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
}

/* @media (min-width: 1200px) and (max-width:1300px) {
  .right-side-image{
    min-height: 350px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 550px 350px;
    margin-left: auto;
  }
  .left-side-image{
    min-height: 350px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 750px 510px;
  }

} */
@media (min-width: 1301px) {
.right-side-image{
  min-height: 510px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 850px 510px;
  /* margin-left: auto; */
}
.left-side-image{
  min-height: 510px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 850px 510px;
}
.row{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
}
@media (max-width: 576px) {
.prev-next-buttons {
  top: 45%; /* Adjust for smaller screens */
  position: absolute;
}

.prev, .next {
  padding: 5px;
}
}


