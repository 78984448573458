#preloader{
    
    width: 8%;
    height: 8%;
    margin: auto;
    margin-top: 10%;
    z-index: 10000;
    overflow: hidden;
    
    animation: animation1 2000ms ease-in-out 2s infinite;
}
.preloader-img{
    margin-left: auto;
}

@keyframes animation1{
      0% {
        transform: scale(1, 1);
        opacity: 1;
        fill: black;
      }
      
      50% {
        transform: scale(0.5, 0.5);
        opacity: 1;
        fill: #dab161;
      }
    
      100% {
        transform: scale(1, 1);
        opacity: 1;
        fill: black;
      }
      
}


