.project-details{
    display: flex;
    justify-content: center;
    border-top: #fcc201 2px solid;
    border-bottom: #fcc201 2px solid;
    padding-top: 1%;
    padding-bottom: 1%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    font-size: 28px;
}
.project-details .left{
    border-right: #fcc201 1px solid;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.project-details .right{
    border-left: #fcc201 1px solid; 
    padding-left: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.design-heading .title{
    text-align: center;
    width: 60%;
    margin-left: 20%;
}
.photo-album{
    margin-top: 50px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.slideshow-main-container{
    padding: 30px 50px; 
    width: 60%;
    border-top: #fcc201 2px solid;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}

.detailed-page-showcase-text{
    text-align: center;
    margin-top: 2%;
}
.design-heading{
    margin-top: 8%;
    padding-bottom: 2%;
}
.detailed-page-showcase-text{
    width: 50%;
    margin-left: 25%;
}
.lead-text{
    font-size: 18px;
}
@media (max-width: 420px){
    .design-heading{
        margin-top: 35%;
        padding-bottom: 30px;
    }

    .slideshow-main-container{
        border: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .detailed-page-showcase-text{
        width: 90%;
        margin-left: 5%;
    }
    .lead-text{
        font-size: 14px;
    }
    .project-details{
        padding-top: 2%;
        padding-bottom: 2%;
        width: 90%;
        margin-bottom: 5%;
    }
    .project-details .left{
        padding-right: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .project-details .right{
        padding-left: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
}

@media (min-width:421px) and (max-width: 768px) {

    .design-heading{
        margin-top: 35%;
        padding-bottom: 30px;
    }

    .slideshow-main-container{
        border: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .detailed-page-showcase-text{
        width: 90%;
        margin-left: 5%;
    }
    .project-details{
        padding-top: 2%;
        padding-bottom: 2%;
        width: 90%;
        margin-bottom: 5%;
    }
    .project-details .left{
        padding-right: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .project-details .right{
        padding-left: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
  }

  @media (min-width:769px) and (max-width: 1025px){  
    .design-heading{
        margin-top: 20%;
        padding-bottom: 30px;
    }
    .slideshow-main-container{
        border: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .detailed-page-showcase-text{
        width: 70%;
        margin-left: 15%;
    }
    .lead-text{
        font-size: 18px;
    }
    .project-details{
        padding-top: 2%;
        padding-bottom: 2%;
        width: 90%;
        margin-bottom: 5%;
    }
    .project-details .left{
        padding-right: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .project-details .right{
        padding-left: 10%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
  }

  

  



  